import './style.css'

interface ToastOptions {
  status?: 'info' | 'error' | 'warning' | 'success'
  message: string
  actionLabel?: string
  timeout?: number
  maxStack?: number
  callback?: () => void
}

const instances: Toast[] = []
let instanceStackStatus = true
const defaultMaxStack = 3
const defaultStatus = 'info'
const statusColorMap = {
  info: '--active-primary',
  error: '--red-50',
  success: '--green-50',
  warning: '--orange-50',
}

class Toast {
  options: ToastOptions
  wrapper: HTMLDivElement
  el?: HTMLDivElement
  private timeoutId?: number
  private visibilityTimeoutId?: number

  constructor(options: ToastOptions) {
    this.options = Object.assign(
      {},
      {
        status: defaultStatus,
        timeout: 3000,
        maxStack: defaultMaxStack,
        actionLabel: '',
      },
      options,
    )
    this.wrapper = this.getWrapper()
    this.insert()
    instances.push(this)
    this.stack()
  }

  getWrapper(): HTMLDivElement {
    let wrapper = document.querySelector(`.toaster`) as HTMLDivElement
    if (!wrapper) {
      wrapper = document.createElement('div')
      wrapper.className = `toaster`
      document.body.appendChild(wrapper)
    }
    return wrapper
  }

  insert() {
    const { callback } = this.options

    const el = document.createElement('div')
    el.className = 'toast'
    el.setAttribute('aria-live', 'assertive')
    el.setAttribute('aria-atomic', 'true')
    el.setAttribute('aria-hidden', 'false')
    el.style.setProperty('--toast-color', `var(${statusColorMap[this.options.status || defaultStatus]})`)

    // Container for center-alignment and space between stacked toasts
    const container = document.createElement('div')
    container.className = 'toast__container'
    el.appendChild(container)

    const text = document.createElement('div')
    text.className = 'toast__text'
    if (callback) text.innerHTML = this.options.message
    else text.innerText = this.options.message

    container.appendChild(text)

    const toastButton = text.getElementsByTagName('button')
    if (toastButton && callback)
      toastButton[0].addEventListener('click', () => {
        callback()
      })

    if (!callback) {
      const button = document.createElement('button')
      button.innerText = 'x'
      button.classList.add('toast__close')

      button.addEventListener('click', () => {
        this.stopTimer()
        this.destroy()
      })
      container.appendChild(button)
    }

    this.startTimer()

    el.addEventListener('mouseenter', () => this.expand())
    el.addEventListener('mouseleave', () => this.stack())

    this.el = el
    this.wrapper.appendChild(el)
  }

  stack() {
    instanceStackStatus = true
    const positionInstances = instances
    const l = positionInstances.length - 1
    positionInstances.forEach((instance, i) => {
      // Resume all instances' timers if applicable
      instance.startTimer()
      const { el } = instance
      if (el) {
        el.style.transform = `translate3d(-50%, -${(l - i) * 15}px, 0) scale(${1 - 0.05 * (l - i)})`
        const hidden = l - i >= (this.options.maxStack || defaultMaxStack)
        this.toggleVisibility(el, hidden)
      }
    })
  }

  expand() {
    instanceStackStatus = false
    const positionInstances = instances
    const l = positionInstances.length - 1
    positionInstances.forEach((instance, i) => {
      // Stop all instances' timers to prevent destroy
      instance.stopTimer()
      const { el } = instance
      if (el) {
        el.style.transform = `translate3d(-50%, -${(l - i) * el.clientHeight}px, 0) scale(1)`
        const hidden = l - i >= (this.options.maxStack || defaultMaxStack)
        this.toggleVisibility(el, hidden)
      }
    })
  }

  toggleVisibility(el: HTMLDivElement, hidden: boolean) {
    if (hidden) {
      this.visibilityTimeoutId = window.setTimeout(() => {
        el.style.visibility = 'hidden'
      }, 300)
      el.style.opacity = '0'
    } else {
      if (this.visibilityTimeoutId) {
        clearTimeout(this.visibilityTimeoutId)
        this.visibilityTimeoutId = undefined
      }
      el.style.opacity = '1'
      el.style.visibility = 'visible'
    }
  }

  /**
   * Destory the toast
   */
  async destroy() {
    const { el, wrapper } = this
    if (el) {
      // Animate the toast away.
      el.setAttribute('aria-hidden', 'true')
      await new Promise<void>((resolve) => el.addEventListener('animationend', () => resolve()))
      wrapper.removeChild(el)
      // Remove instance from the instances array
      const positionInstances = instances
      let index: number | undefined = undefined
      for (let i = 0; i < positionInstances.length; i++) {
        if (positionInstances[i].el === el) {
          index = i
          break
        }
      }
      if (index !== undefined) positionInstances.splice(index, 1)
      // Based on current status, refresh stack or expand style
      if (instanceStackStatus) this.stack()
      else this.expand()
    }
  }

  startTimer() {
    if (this.options.timeout && !this.timeoutId) {
      this.timeoutId = self.setTimeout(() => this.destroy(), this.options.timeout)
    }
  }

  stopTimer() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId)
      this.timeoutId = undefined
    }
  }
}

export const toast = (options: ToastOptions) => new Toast(options)
