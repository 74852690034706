import type { GetActivityNotificationsResponse, ReceivedReaction } from '@klausapp/services/reviews'
import type { GetFeedbackReceivedResponse_Feedback as SurveyFeedbackResponse } from '@klausapp/services/surveys'
import { api } from '@/api'
import { session } from '@/composables/useSession'
import type {
  ReviewListParams,
  Review,
  CommentListParams,
  Comment,
  DisputeType,
  DisputeListParams,
  Dispute,
  FeedbackListParams,
  ReactionListParams,
} from '../types'

export let activityController: AbortController
export let activitiesCountController: AbortController

const getWorkspaceHeader = () => ({ 'X-Klaus-Workspace': session.workspace.id.toString() })

export const getReviews = ({
  page,
  limit,
  commentsOnly,
  outbound,
  userId,
  sort,
}: ReviewListParams): Promise<{ reviews: Review[]; total: string }> => {
  activityController?.abort()

  activityController = new AbortController()

  const path = outbound ? 'reviews/v2/given' : 'reviews/v2/received'
  const searchParams = {
    limit: `${limit}`,
    offset: `${limit * page}`,
    sort,
  }
  const query = new URLSearchParams(searchParams)

  if (commentsOnly) query.append('reviewType', 'comment')
  if (userId) query.append('userId', userId.toString())

  return api
    .get(`${path}?${query.toString()}`, {
      headers: getWorkspaceHeader(),
      signal: activityController.signal,
    })
    .json()
}

export const getComments = ({
  page,
  limit,
  type,
  outbound,
  sort,
}: CommentListParams): Promise<{ comments: Comment[]; total: string }> => {
  activityController?.abort()

  activityController = new AbortController()

  const path = outbound ? 'comments/given' : 'comments/received'
  const searchParams = {
    limit: `${limit}`,
    offset: `${limit * page}`,
    sort,
    type,
  }
  const query = new URLSearchParams(searchParams)

  return api
    .get(`${path}?${query.toString()}`, {
      headers: getWorkspaceHeader(),
      signal: activityController.signal,
    })
    .json()
}

export const getReactions = ({
  page,
  limit,
}: ReactionListParams): Promise<{ reactions: ReceivedReaction[]; total: string }> => {
  activityController?.abort()

  activityController = new AbortController()

  const query = new URLSearchParams({
    limit: `${limit}`,
    offset: `${limit * page}`,
  })

  return api
    .get(`reactions/received?${query.toString()}`, {
      headers: getWorkspaceHeader(),
      signal: activityController.signal,
    })
    .json()
}

export const getSurveyFeedback = ({
  page,
  limit,
  sort,
  questionTypeId,
}: FeedbackListParams & { page: number }): Promise<{ feedback: SurveyFeedbackResponse[]; total: string }> => {
  activityController?.abort()

  activityController = new AbortController()

  const searchParams = {
    limit,
    offset: ((limit as any) * page).toString(),
    sort,
  }

  if (questionTypeId) searchParams['questionTypeId'] = questionTypeId.toString()

  const query = new URLSearchParams(searchParams)

  return api
    .get(`surveys/feedback/received?${query.toString()}`, {
      headers: getWorkspaceHeader(),
      signal: activityController.signal,
    })
    .json()
}

export const getDisputes = ({
  endpoint,
  page,
  limit,
  status,
  sort,
}: DisputeListParams): Promise<{ disputes: Dispute[]; total: string }> => {
  activityController?.abort()

  activityController = new AbortController()

  const path = `disputes/${endpoint}`
  const searchParams = {
    limit: `${limit}`,
    offset: `${limit * page}`,
    sort,
    status,
  }
  const query = new URLSearchParams(searchParams)

  return api
    .get(`${path}?${query.toString()}`, {
      headers: getWorkspaceHeader(),
      signal: activityController.signal,
    })
    .json()
}

export const getActivitiesCount = (): Promise<GetActivityNotificationsResponse> => {
  activitiesCountController?.abort()

  activitiesCountController = new AbortController()

  return api
    .get('notifications/activity', {
      headers: getWorkspaceHeader(),
      signal: activitiesCountController.signal,
    })
    .json()
}

export const markAllReviewsRead = () => api.put('reviews/v2/received/viewed', { headers: getWorkspaceHeader() })

export const markAllCommentsRead = () => api.put('comments/received/viewed', { headers: getWorkspaceHeader() })

export const markAllDisputesRead = (type: DisputeType) =>
  api.put(`disputes/notifications/${type}/mark-seen`, { headers: getWorkspaceHeader() })
