import { type Placement } from 'tippy.js'
import type defaultProps from './defaultProps'

export enum Modes {
  Single = 'single',
  Multiple = 'multiple',
}

export enum DropdownButtonColor {
  White = 'white',
  Default = 'default',
  Transparent = 'transparent',
}

export enum SelectAllModes {
  All = 'all',
  IntermediaryAll = 'intermediaryAll',
  None = 'none',
}

export interface DropdownProps<
  T extends object,
  P extends keyof T,
  L extends keyof T,
  M extends Modes,
  V extends T,
  O extends boolean = false,
> {
  mode: M
  isObject?: O
  propToCheck: P
  options?: T[]
  modelValue: O extends true ? V[] : V[P][]
  closeOnSelect?: boolean
  selectAllLabel?: string
  maxHeight?: number
  triggerLabel?: string
  label: L
  searchPlaceholder?: string
  noResultsText?: string
  loadingText?: string
  selectAllMode?: SelectAllModes
  filterResults?: boolean
  total?: number
  maxWidth?: number
  fullWidth?: boolean
  isLoading?: boolean
  reorderAfterSelect?: boolean
  hasSearch?: boolean
  itemsLabel?: string
  size?: string
  appendToDocument?: boolean
  disabled?: boolean
  excludedOptions?: O extends true ? V[] : V[P][]
  canRemove?: boolean
  buttonColor?: DropdownButtonColor
  selectedOptionAvatar?: boolean
  groups?: OptionGroup<T>[]
  placement?: Placement
}

export interface DropdownEmits<T> {
  (e: 'update:modelValue', value: (string | number | T)[], excludedValue?: (string | number | T)[]): void
  (e: 'search-change', query: string): void
}

type Defaults = typeof defaultProps

declare type NotUndefined<T> = T extends undefined ? never : T

export type PropsWithDefaults<
  T extends object,
  P extends keyof T,
  L extends keyof T,
  M extends Modes,
  V extends T,
  O extends boolean,
> = DropdownProps<T, P, L, M, V, O> & {
  [K in keyof Defaults]: K extends keyof DropdownProps<T, P, L, M, V, O>
    ? Defaults[K] extends undefined
      ? DropdownProps<T, P, L, M, V, O>[K]
      : NotUndefined<DropdownProps<T, P, L, M, V, O>[K]>
    : never
}

export type Selected<T extends object, P extends keyof T, M extends Modes, O extends boolean> = O extends true
  ? M extends typeof Modes.Single
    ? T
    : T[]
  : M extends typeof Modes.Single
    ? T[P]
    : T[P][]

export type Value<T extends object, P extends keyof T, O extends boolean> = O extends true ? T : T[P]

export interface UpdateParams<T extends object, P extends keyof T, M extends Modes, O extends boolean> {
  selected: Selected<T, P, M, O>
  excluded?: Selected<T, P, M, O>
}

export interface OptionGroup<T> {
  id?: string
  name?: string
  options: T[]
  total?: number
  emptyText?: string
}

export type AllOption = Record<string, string | number>
