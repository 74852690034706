import type { RouteLocationNormalized, RouteLocationRaw, NavigationGuard } from 'vue-router'

import { session } from '@/composables/useSession'
import { hasAccountRole, hasWorkspaceRole } from '@/utils/roleUtils'
import type { AccountRole, WorkspaceRole } from '@/data/roles'
import type { Features } from '@/types/session'

/**
 * Do redirects for agents if self-reviews are not allowed
 */
export const getAgentSelfReviewRoute = (to: RouteLocationNormalized): RouteLocationRaw | null => {
  if (
    hasAccountRole('ADMIN', 'MANAGER') ||
    hasWorkspaceRole('MANAGER', 'LEAD', 'REVIEWER') ||
    session.workspace.settings.selfReviews ||
    !to.name
  ) {
    return null
  }

  const routeName = to.name.toString()

  if (routeName.startsWith('conversations') && to.query.singleTicket) return null

  if (routeName === 'conversations.csat' && to.params.connectionId) {
    return {
      name: 'activity.feedbackReceived.csat',
      params: { connectionId: to.params.connectionId, conversationId: to.params.conversationId },
    }
  }

  if (['conversations', 'activity.reviewsGiven'].includes(routeName) && to.params.connectionId) {
    return {
      name: 'activity.reviewsReceived',
      params: { connectionId: to.params.connectionId, conversationId: to.params.conversationId },
    }
  }

  if (['dashboard', 'activity.reviewsReceived'].includes(routeName)) return null

  return {
    name: 'activity.reviewsReceived',
    params: { connectionId: to.params.connectionId, conversationId: to.params.conversationId },
  }
}

/**
 * Support old feedback routes with `reviewMessage` query param
 */
export const redirectToMessageView =
  (name: string): NavigationGuard =>
  (to, _from, next) => {
    const { reviewMessage: messageId, ...query } = to.query
    if (!messageId) return next()
    if (Array.isArray(messageId)) throw new Error('Multiple reviewMessage params!')

    return next({ name: `${name}.message`, params: { ...to.params, messageId }, query })
  }

interface Meta {
  hideNav?: boolean
  lightBackground?: boolean
  requiredHighestWorkspaceRole?: WorkspaceRole[]
  requiredAccountRole?: AccountRole[]
  requiresPayment?: boolean
  skipAuth?: boolean
  requiredFlag?: keyof Features
  allowEmbedding?: boolean
  hideProxied?: boolean
  requiresSufficientConversations?: boolean
}

declare module 'vue-router' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface RouteMeta extends Meta {}
}

/**
 * Pull out last matched route meta value
 */
export function routeMetavalue(
  route: RouteLocationNormalized,
  key:
    | 'hideNav'
    | 'lightBackground'
    | 'requiresPayment'
    | 'skipAuth'
    | 'allowEmbedding'
    | 'hideProxied'
    | 'requiresSufficientConversations',
): boolean | undefined
export function routeMetavalue(route: RouteLocationNormalized, key: 'requiredFlag'): keyof Features | undefined
export function routeMetavalue(route: RouteLocationNormalized, key: 'requiredAccountRole'): AccountRole[]
export function routeMetavalue(route: RouteLocationNormalized, key: 'requiredHighestWorkspaceRole'): WorkspaceRole[]
export function routeMetavalue(route: RouteLocationNormalized, key: keyof Meta) {
  const values = route.matched.filter((r) => key in r.meta).map((r) => r.meta[key])
  return values[values.length - 1]
}

export function getFirstParam(param: string | string[]) {
  if (Array.isArray(param)) {
    if (param.length) return param[0]
    return null
  }

  return param
}
