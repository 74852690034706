import type { WorkspaceRole } from '@/data/roles'
import type { DefaultReviewee } from './settings'

export enum ReviewerReviewVisibility {
  Own = 'own',
  AllAfterSubmitting = 'all_after_submitting',
}

export enum LeadReviewVisibility {
  AllAfterSubmitting = 'all_after_submitting',
  All = 'all',
}

export enum ManagerReviewVisibility {
  AllAfterSubmitting = 'all_after_submitting',
  All = 'all',
}

export enum ScorecardType {
  Static = 'static',
  Dynamic = 'dynamic',
}

export interface WorkspaceSettings {
  calibrated: boolean
  calibrationEnabled: boolean
  defaultReviewee: DefaultReviewee
  limitConnections: any
  managerVisibility: ManagerReviewVisibility
  reviewerVisibility: ReviewerReviewVisibility
  leadVisibility: LeadReviewVisibility
  scorecardType: ScorecardType
  selfReviews: boolean
  tagsLocked: boolean
  threshold: number
}

export interface WorkspaceItem {
  id: number
  name: string
  color: string
  totalMembers: number
  role?: WorkspaceRole
  settings?: WorkspaceSettings
}
