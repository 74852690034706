import qs from 'query-string'

import type { NormalizedOptions } from 'ky'
import { api, errorHandlerHook } from '@/api'
import { session } from '@/composables/useSession'
import type { InternalUser } from '@/types/user'
import type {
  AssignmentUser,
  Assignment,
  AssignmentTicketListItem,
} from '@/modules/workspace/views/assignments/types/assignments'

export interface AssignmentSetup {
  assignment: Partial<Assignment>
  counts: {
    reviewsGivenForPeriod: string
    reviewsRemainingForPeriod: string
    reviewsRequiredForPeriod: string
  }
  cycleDates: {
    currentCycleEnd: string
    currentCycleStart: string
    firstCycleStart: string
  }
  peer: InternalUser
  tickets: AssignmentTicketListItem[]
  hasTickets?: boolean
  revieweesForCurrentCycle?: AssignmentUser[]
}

export interface AssignmentDashboardData {
  assignment: Assignment
  dataLabels: {
    cycle: string
    cycleStart: string
    cycleEnd: string
    activeCycle: boolean
  }[]
  dataTotals: number[]
  pagination: {
    page: string
    pageSize: string
    total: string
  }
  rows: {
    total: string
    totalPercentage: number
    data: {
      value: string
      goal: string
    }[]
    reviewer: AssignmentUser
  }[]
  total: string
  totalPercentage: number
}

interface SkipPayload {
  assignmentId: number
  assignmentRevieweeId: string
  ticketsToExclude: { connectionId: number; externalTicketId: string }[]
}

export const getAssignments = (): Promise<{ assignments: AssignmentSetup[] }> =>
  api
    .get('assignments', {
      headers: { 'X-Klaus-Workspace': session.workspace.id.toString() },
    })
    .json()

const handleDowngradedConditions = (request: Request, options: NormalizedOptions, response: Response) => {
  if (response.status !== 404) errorHandlerHook(request, options, response)
}

export const getAssignmentTicket = (
  assignmentId: string,
  validateConditions = true,
): Promise<{ ticket: AssignmentTicketListItem }> =>
  api
    .extend({ hooks: { afterResponse: undefined } })
    .extend({ hooks: { afterResponse: validateConditions ? [handleDowngradedConditions] : [] } })
    .get(`assignments/${assignmentId}/ticket`, {
      headers: { 'X-Klaus-Workspace': session.workspace.id.toString() },
    })
    .json()

export const getAssignmentNextTicket = (assignmentId: string): Promise<{ ticket: AssignmentTicketListItem }> =>
  api
    .post(`assignments/${assignmentId}/ticket`, {
      headers: { 'X-Klaus-Workspace': session.workspace.id.toString() },
    })
    .json()

export const getAssignmentDashboard = ({
  assignmentId,
  fromDate,
  toDate,
  pageSize,
  page,
  workspaceIds,
}): Promise<AssignmentDashboardData> => {
  return api
    .get(`assignments/${assignmentId}/dashboard`, {
      headers: { 'X-Klaus-Workspace': workspaceIds.join() },
      searchParams: qs.stringify({ fromDate, toDate, pageSize, page }, { skipNull: true }),
    })
    .json()
}

export const getAssignmentsCount = (): Promise<{ count: string }> =>
  api
    .get('assignments/reviews-remaining', {
      headers: { 'X-Klaus-Workspace': session.workspace.id.toString() },
    })
    .json()

export const skipTickets = ({
  assignmentId,
  assignmentRevieweeId,
  ticketsToExclude,
}: SkipPayload): Promise<{ ticket: AssignmentTicketListItem | null }> =>
  api
    .post(`assignments/${assignmentId}/skip-tickets`, {
      headers: { 'X-Klaus-Workspace': session.workspace.id.toString() },
      json: {
        assignmentRevieweeId,
        ticketsToExclude,
      },
    })
    .json()
