import { getLocale } from '@/i18n'

export const klausappHost = 'https://klausapp.com'

export const localizeSupportLink = (link: string) => {
  return link
}

export const getKlausappPath = () => {
  const lang = `${getLocale().split('-')[0].toLowerCase()}`
  if (lang === 'en') return klausappHost
  return `${klausappHost}/${lang}`
}
