export enum RootViews {
  Dashboard = 'dashboard',
  Conversations = 'conversations',
  Assignments = 'assignments',
  Activity = 'activity',
  Coaching = 'coaching',
  Workspaces = 'workspaces',
  Settings = 'settings',
  Tasks = 'tasks',
}

export const TICKET_VIEWS = [RootViews.Conversations, RootViews.Assignments, RootViews.Tasks, RootViews.Activity]

export interface LayoutState {
  leftSidebar: string
  leftSidebarSize: number
  rightSidebar: string
  rightSidebarSize: number
  showPageNav: boolean
  contentSidebarSize: number
}
