import { init } from '@sentry/vue'

import config, { embeddedRuntime } from './config'
import { APIError } from './api'

export const initSentry = () => {
  const isMonitoring = navigator.userAgent.startsWith('klaus/monitoring/')
  if (isMonitoring || !config.sentryDsn || !config.sentryEnv) return

  init({
    app: [],
    dsn: config.sentryDsn,
    release: config.gitCommit,
    environment: config.sentryEnv,
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      'NavigationDuplicated',
      'Navigation cancelled from "/extension',
      'The user aborted a request.',
      'Timeout',
      'base-uri - ',
      'connect-src - ',
    ],
    // Chrome extensions
    denyUrls: [/extensions\//i, /^chrome:\/\//i, /consent.cookiebot.com/],
    beforeSend(event, hint) {
      event.tags = event.tags || {}

      if (hint?.originalException instanceof APIError && hint.originalException.traceContext) {
        const [traceId, spanId] = hint.originalException.traceContext.split('/')
        event.tags['trace.id'] = traceId
        event.tags['trace.span_id'] = spanId.split(';')[0]
      }

      if (embeddedRuntime()) event.tags.fromExtension = true

      return event
    },
  })
}
